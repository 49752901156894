:root {
    --logo-url: url('/images/logos/rivkin-logo.png'); 
    // --logo-height: auto;
    // --logo-width: auto;
}
// Variables

$spacer-lg                      : 3rem;
$spacer-sm                      : 1.5rem;

$layout-spacer-lg               : 3rem;
$layout-spacer-x                : 1.5rem;

$app-sidebar-width              : 280px;
$app-sidebar-width-collapsed    : 80px;

$logo-height                    : auto; // var(--logo-height);// 31px;
$logo-width                     : auto; // var(--logo-width);// 171px;

$app-header-height              : 60px;