:root {
  --logo-url: url('/images/logos/rivkin-logo.png'); 
  // --logo-height: auto;
  // --logo-width: auto;
}
// User Pages

.app-logo {
  height: $logo-height;
  width: $logo-width;
  background: var(--logo-url); // url('~assets/utils/images/logo-inverse.png');
}

.app-logo-inverse {
  height: $logo-height;
  width: $logo-width;
  background: var(--logo-url); // url('~assets/utils/images/logo.png');
  background-image: var(--logo-url); 
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 80px;
  width: inherit;
  max-width: 200px;
}

.app-login-box {
  .app-logo {
    margin-bottom: $layout-spacer-lg;
  }

  h4 {
    margin-bottom: $layout-spacer-x;
    font-weight: normal;

    div {
      opacity: .6;
    }

    span {
      font-size: $font-size-lg;
    }
  }
}